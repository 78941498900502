import React, { useState } from 'react';
import { Menu } from 'react-windy-ui';
import './css/menu.css';

const LeftTabBar = ({ tabs, currentTab, setCurrentTab, msg }) => {
  const [bottomBar] = useState(true);
  const [headData,setheadData]=useState("")
  const svgIcons = [
    {
      svgIcons: (
        <svg
          d="1650289682211"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="10311"
          width="20"
          height="20"
        >
          <path
            d="M586.945923 513.581008c55.067176-27.962865 92.91211-85.125773 92.91211-150.998039 0-93.338828-75.937506-169.276335-169.277358-169.276335s-169.275311 75.937506-169.275311 169.276335c0 65.872267 37.844933 123.034151 92.911086 150.998039-95.652524 32.016181-164.778904 122.45496-164.778904 228.743728 0 11.31572 9.17394 20.491707 20.491707 20.491707s20.491707-9.174963 20.491707-20.491707c0-110.36869 89.791026-200.160739 200.160739-200.160739S710.741413 631.956046 710.741413 742.324736c0 11.31572 9.17394 20.491707 20.491707 20.491707s20.491707-9.174963 20.491707-20.491707C751.723803 636.035968 682.598446 545.598212 586.945923 513.581008zM382.287753 362.582969c0-70.742181 57.552787-128.293945 128.292921-128.293945 70.742181 0 128.293945 57.552787 128.293945 128.293945 0 70.741157-57.552787 128.292921-128.293945 128.292921C439.84054 490.876913 382.287753 433.324126 382.287753 362.582969z"
            p-id="10312"
            fill="#515151"
          />
          <path
            d="M827.871087 196.127889C743.498468 111.757317 631.320573 65.290005 512 65.290005S280.500509 111.756293 196.128913 196.127889C111.756293 280.501532 65.291029 392.678404 65.291029 511.998977s46.465265 231.499491 130.837884 315.872111 196.550515 130.837884 315.871087 130.837884 231.498468-46.465265 315.871087-130.837884S958.708971 631.319549 958.708971 511.998977 912.243707 280.500509 827.871087 196.127889zM512 917.726581c-223.718271 0-405.726581-182.007287-405.726581-405.727605 0-223.718271 182.00831-405.726581 405.726581-405.726581s405.726581 182.007287 405.726581 405.726581C917.726581 735.719294 735.718271 917.726581 512 917.726581z"
            p-id="10313"
            fill="#515151"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289151309"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3053"
          width="20"
          height="20"
        >
          <path
            d="M808.5 917.4H218c-43 0-78-35-78-78V197c0-38.8 28.5-71.1 65.8-77 2.4-0.9 5.1-1.2 7.7-0.8 1.5-0.2 3-0.2 4.5-0.2h584.5c46.3 0 84 37.7 84 84v9c0 46.3-37.7 84-84 84H221.3c-7.8 0-14.5-5.3-16.5-12.8-1.9-7.5 1.5-15.4 8.3-19.1 24.7-13.5 36.2-32.3 36.2-59.2 0-15.5-4.2-37.3-39.2-51.2-20.5 3.7-36.1 21.7-36.1 43.3v642.4c0 24.3 19.7 44 44 44h590.5c24.3 0 44-19.7 44-44V324.9c0-9.4 7.6-17 17-17s17 7.6 17 17v514.5c0 43-35 78-78 78zM266.2 262h536.3c27.6 0 50-22.4 50-50v-9c0-27.6-22.4-50-50-50H266.4c10.1 12.9 16.9 29.8 16.9 51.9 0.1 21.8-5.8 41-17.1 57.1z"
            fill="#515151"
            p-id="3054"
          />
          <path
            d="M760.6 399H263c-9.4 0-17-7.6-17-17s7.6-17 17-17h497.6c9.4 0 17 7.6 17 17s-7.6 17-17 17zM640 495H259c-9.4 0-17-7.6-17-17s7.6-17 17-17h381c9.4 0 17 7.6 17 17s-7.6 17-17 17zM559 605H259c-9.4 0-17-7.6-17-17s7.6-17 17-17h300c9.4 0 17 7.6 17 17s-7.6 17-17 17zM513 864c-4.3 0-8.5-1.6-11.7-4.7-4.2-4-6.1-9.9-5-15.7l20.1-100c0.6-3 2-5.8 4-8l262-290.9c6.3-7 17-7.5 24-1.3 7 6.3 7.5 17 1.3 24L548.8 754.8l-13.8 69 61-15.5 186.3-212.7c6.2-7.1 16.9-7.8 24-1.6 7.1 6.2 7.8 16.9 1.6 24L618.1 834.7c-2.3 2.6-5.3 4.4-8.6 5.3l-92.3 23.5c-1.4 0.3-2.8 0.5-4.2 0.5z"
            fill="#515151"
            p-id="3055"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289271988"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3593"
          width="20"
          height="20"
        >
          <path
            d="M941.526161 198.659612l-219.729571 0L721.79659 122.205761c0-45.751911-37.323927-82.473839-82.473839-82.473839L384.677249 39.731922c-45.751911 0-82.473839 37.323927-82.473839 82.473839L302.20341 198.659612 82.473839 198.659612C37.323927 198.659612 0 235.983539 0 281.133451l0 620.058789c0 45.751911 37.323927 82.473839 82.473839 82.473839l858.450323 0c45.751911 0 82.473839-37.323927 82.473839-82.473839l0-620.058789C1024 235.983539 986.676073 198.659612 941.526161 198.659612zM356.383304 122.205761c0-15.651969 12.641975-28.895944 28.895944-28.895944l254.645503 0c15.651969 0 28.895944 12.641975 28.895944 28.895944L668.820694 198.659612 356.383304 198.659612 356.383304 122.205761zM352.771311 630.292769c15.049971 0 27.089947-12.039976 27.089947-27.089947l0-40.333921L644.138742 562.868901l0 40.333921c0 15.049971 12.039976 27.089947 27.089947 27.089947 15.049971 0 27.089947-12.039976 27.089947-27.089947l0-40.333921 272.103469 0 0 338.925338c0 15.651969-12.641975 28.895944-28.895944 28.895944L82.473839 930.690182c-15.651969 0-28.895944-12.641975-28.895944-28.895944L53.577895 562.868901l272.103469 0 0 40.333921C325.681364 618.252792 338.323339 630.292769 352.771311 630.292769zM671.228689 449.091123c-15.049971 0-27.089947 12.039976-27.089947 27.089947l0 32.507937L379.861258 508.689006l0-32.507937c0-15.049971-12.039976-27.089947-27.089947-27.089947s-27.089947 12.039976-27.089947 27.089947l0 32.507937L54.179894 508.689006 54.179894 281.133451c0-15.651969 12.641975-28.895944 28.895944-28.895944l858.450323 0c15.651969 0 28.895944 12.641975 28.895944 28.895944l0 227.555556L698.318636 508.689006l0-32.507937C698.318636 461.131099 685.676661 449.091123 671.228689 449.091123z"
            p-id="3594"
            fill="#515151"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289370453"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3913"
          width="20"
          height="20"
        >
          <path
            d="M511.786667 893.44c-144.213333 0-261.546667-117.333333-261.546667-261.546667v-171.52h64v171.52c0 109.013333 88.746667 197.546667 197.546667 197.546667s197.546667-88.746667 197.546666-197.546667v-171.52h64v171.52c0 144.213333-117.333333 261.546667-261.546666 261.546667z"
            fill="#515151"
            p-id="3914"
          />
          <path
            d="M510.933333 198.186667l296.32 181.12-296.32 181.12-296.32-181.12 296.32-181.12m0-67.626667c-7.68 0-15.36 2.133333-22.186666 6.186667L151.466667 342.826667a42.538667 42.538667 0 0 0 0 72.746666l337.28 206.08c6.826667 4.266667 14.506667 6.186667 22.186666 6.186667s15.36-2.133333 22.186667-6.186667l337.28-206.08a42.538667 42.538667 0 0 0 0-72.746666L533.333333 136.746667c-6.826667-4.266667-14.506667-6.186667-22.4-6.186667z"
            fill="#515151"
            p-id="3915"
          />
          <path
            d="M860.8 577.706667c-17.706667 0-32-14.293333-32-32v-170.666667c0-17.706667 14.293333-32 32-32s32 14.293333 32 32v170.666667c0 17.493333-14.293333 32-32 32z"
            fill="#515151"
            p-id="3916"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289400346"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4696"
          width="20"
          height="20"
        >
          <path
            d="M849 384h-80c0 130.547-97.719 238.264-224 254.012V768h36.601c7.537 0 17.284 6.383 22.244 15.089l65.868 115.614c17.061 29.948 20.45 61.297 2.921 61.297h-320c-17.53 0-13.899-31.352 3.389-61.296l66.75-115.614c5.027-8.707 14.822-15.09 22.359-15.09H481V638.012C354.719 622.264 257 514.547 257 384h-80c-35.346 0-64-28.654-64-64v-64c0-35.346 28.654-64 64-64h80v-64c0-35.346 28.654-64 64-64h384c35.346 0 64 28.654 64 64v64h80c35.346 0 64 28.654 64 64v64c0 35.346-28.654 64-64 64zM257 256h-46c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32h46v-64z m309.061-15.833L513 144.007l-53.062 96.16-107.917 20.717 75.122 80.147-13.636 108.962L513 403.361l99.492 46.632-13.636-108.962 75.123-80.147-107.918-20.717zM815 256h-46v64h46c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32z"
            p-id="4697"
            fill="#515151"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289421486"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="5686"
          width="20"
          height="20"
        >
          <path
            d="M351.5 896H256a32 32 0 0 1-31.9-32V160a32 32 0 0 1 31.9-32h448.1A32 32 0 0 1 736 160v256a32 32 0 0 0 64 0V160a96.1 96.1 0 0 0-95.9-96H256A96.2 96.2 0 0 0 160 160v704a96.2 96.2 0 0 0 96 96h95.5a32 32 0 0 0 0-64z"
            fill="#515151"
            p-id="5687"
          />
          <path
            d="M860 816L763.7 650a32.8 32.8 0 0 0-7.7-8.8 189.4 189.4 0 0 0 5.1-42.9 192.1 192.1 0 0 0-384.1 0 189 189 0 0 0 5.2 43.5 32.7 32.7 0 0 0-7 8.2l-96 166a32 32 0 0 0 30 47.9l93.9-6 41.6 84.3A32.1 32.1 0 0 0 472.2 960h1a32 32 0 0 0 27.7-16L570 825.3l68 118.7a32 32 0 0 0 27.7 16h1a32.1 32.1 0 0 0 27.7-17.8l41.6-84.3 93.9 6a32 32 0 0 0 30-47.9zM569.1 470a128 128 0 1 1-128 128A128.1 128.1 0 0 1 569.1 470z m-93.7 390l-24.6-50A32.1 32.1 0 0 0 420 792.6l-55.4 3.6 49.3-85.5A192.8 192.8 0 0 0 520 783.4z m243.5-67.6A32.1 32.1 0 0 0 688.1 810l-24.6 50-44.5-77A192.9 192.9 0 0 0 724.6 710l50 86.1zM672 224a32 32 0 0 0-32-32H320a32 32 0 1 0 0 64h320a32 32 0 0 0 32-32zM320 320a32 32 0 0 0 0 64h160a32 32 0 1 0 0-64z"
            fill="#515151"
            p-id="5688"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289453861"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6798"
          width="20"
          height="20"
        >
          <path
            d="M716.906667 271.232l111.146666 66.688A96 96 0 0 1 874.666667 420.245333V768a96 96 0 0 1-96 96h-512A96 96 0 0 1 170.666667 768V420.245333a96 96 0 0 1 46.613333-82.346666l111.146667-66.666667 32.917333 54.869333-111.146667 66.688A32 32 0 0 0 234.666667 420.266667V768a32 32 0 0 0 32 32h512A32 32 0 0 0 810.666667 768V420.245333a32 32 0 0 0-15.530667-27.456l-111.146667-66.688 32.917334-54.869333zM522.666667 149.333333l128 76.8-54.506667 115.2h-1.856L629.333333 634.389333 522.666667 725.333333l-106.666667-90.944L451.008 341.333333h-1.493333L394.666667 226.133333l128-76.8z m15.381333 192h-30.784l-32.256 269.973334 47.658667 40.618666 47.658666-40.618666L538.026667 341.333333z m-15.381333-117.354666l-45.226667 27.136 12.48 26.218666h65.706667l12.373333-26.154666L522.666667 224z"
            p-id="6799"
            fill="#515151"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289483665"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="7708"
          width="20"
          height="20"
        >
          <path
            d="M512 992c-22.72 0-44.48-7.36-62.912-21.44l-54.528-42.112c-40.96-32-81.856-64.64-120-96.064l-23.616-19.712c-66.752-55.744-112.576-97.728-140.032-128.512C34.24 597.888 0 502.912 0 376.128 0 186.368 139.328 32 310.528 32 384.64 32 456.384 61.568 512 114.176 567.616 61.568 639.36 32 713.408 32 884.672 32 1024 186.368 1024 376.128c0 126.784-34.24 221.76-110.912 307.968-27.264 30.656-73.088 72.704-140.032 128.704l-22.848 19.008c-38.848 32-79.744 64.64-120.64 96.512l-54.016 41.792A103.936 103.936 0 0 1 512 992z m-201.472-896C174.592 96 64 221.632 64 376.128c0 109.952 29.248 191.808 94.72 265.472 25.28 28.288 68.864 68.224 133.248 121.92l23.488 19.584c37.568 30.912 78.08 63.168 118.528 94.784l54.144 41.856a39.296 39.296 0 0 0 48.448-0.448l53.76-41.536a6207.04 6207.04 0 0 0 119.104-95.296l22.656-18.88c64.512-53.888 108.032-93.824 133.12-121.984C930.752 567.936 960 486.08 960 376.128 960 221.632 849.344 96 713.408 96c-67.584 0-130.432 30.336-177.024 85.312L512 210.176l-24.448-28.864C441.024 126.336 378.112 96 310.528 96z"
            p-id="7709"
            fill="#515151"
          />
          <path
            d="M735.296 602.624H288.64a32 32 0 0 1 0-64h446.656a32 32 0 0 1 0 64z"
            p-id="7710"
            fill="#515151"
          />
          <path
            d="M735.296 595.968a32.128 32.128 0 0 1-24.32-11.2l-80-93.312a32 32 0 0 1 48.64-41.6l80 93.312a32 32 0 0 1-24.32 52.8z"
            p-id="7711"
            fill="#515151"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289506010"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="8028"
          width="20"
          height="20"
        >
          <path
            d="M876 416c-0.6 0-1.2 0-1.8-0.1C753.9 403.4 632 397.1 512 397.1s-241.5 6.3-361.2 18.8c-7.5 0.8-14.6-3.4-17.5-10.4s-0.9-14.9 4.9-19.7l247.9-203.6c5.4-4.5 13-5.1 19.1-1.7l74.3 41.9c19.6 11 43.3 10.1 61.9-2.4l57.7-38.8c6-4.1 14-3.8 19.8 0.6l267.4 203.7c6.1 4.6 8.3 12.7 5.5 19.8-2.6 6.5-8.9 10.7-15.8 10.7zM398.6 215.8L202.9 376.7C305.7 367.6 409.4 363 512 363c101.5 0 204.2 4.5 306.3 13.4L608 216.2l-47.6 32c-29.4 19.7-66.8 21.2-97.6 3.8l-64.2-36.2zM164.1 675.8V830h25.3v-68.3h78.2V830h25.3V675.8h-25.3v64.4h-78.2v-64.4h-25.3z m160.1 0V830h114.9v-21.6h-89.6v-46.7h80.8v-21.6h-80.8v-42.8h86v-21.6H324.2z m139.8 0V830h108.4v-21.6H489V675.8h-25z m128.9 0V830h108.4v-21.6H618V675.8h-25.1z m197.5-3c23.5 0 42.1 7.6 55.7 22.9 13 14.5 19.4 33.7 19.4 57.5s-6.5 42.8-19.4 57.2c-13.6 15.1-32.2 22.7-55.7 22.7-23.8 0-42.3-7.8-55.7-22.9-13-14.7-19.2-33.7-19.2-57 0-23.5 6.3-42.6 19.2-57.2 13.3-15.7 31.9-23.2 55.7-23.2z m0 22.2c-16 0-28.3 5.4-37.2 16.2-8.4 10.4-12.5 24.2-12.5 41.9 0 17.5 4.1 31.3 12.5 41.7 8.6 10.6 21.2 16 37.2 16s28.3-5.2 36.9-15.3c8.4-10.2 12.7-24.2 12.7-42.3 0-18.1-4.3-32.4-12.7-42.8-8.6-10.4-20.9-15.4-36.9-15.4z"
            fill="#515151"
            p-id="8029"
          />
          <path
            d="M893 902H136c-20.4 0-37-16.6-37-37V645c0-20.4 16.6-37 37-37h76.2c9.9 0 19.1-3.8 26.1-10.8l129.2-128.9c14.4-14.4 37.9-14.4 52.3 0.1 6.4 6.4 10.2 14.9 10.7 23.9l4.9 81c1.2 19.5 17.4 34.8 36.9 34.8H893c20.4 0 37 16.6 37 37v220c0 20.3-16.6 36.9-37 36.9zM136 642c-1.7 0-3 1.3-3 3v220c0 1.7 1.3 3 3 3h757c1.7 0 3-1.3 3-3V645c0-1.7-1.3-3-3-3H472.4c-37.5 0-68.6-29.3-70.9-66.8l-4.9-81c0-0.7-0.4-1.4-0.9-1.9-1.2-1.2-3.1-1.2-4.2 0l-129.2 129c-13.4 13.4-31.2 20.7-50.1 20.7H136z"
            fill="#515151"
            p-id="8030"
          />
          <path
            d="M459 514c-6.4 0-12.5-3.6-15.4-9.8-4-8.5-0.4-18.6 8.1-22.6l27.7-13.1c16.9-8 36.4-6.8 52.3 3.1l3.8 2.4c4.6 2.9 10.2 3.7 15.4 2.2l314.4-90.7c9-2.6 18.4 2.6 21 11.6s-2.6 18.4-11.6 21l-314.4 90.7c-14.5 4.2-30.1 2-42.9-6l-3.8-2.4c-6-3.7-13.3-4.2-19.7-1.2l-27.7 13.1c-2.3 1.2-4.7 1.7-7.2 1.7z m-140.5-43.7c-1.7 0-3.4-0.2-5.1-0.8L148 418.1c-9-2.8-14-12.3-11.2-21.3 2.8-9 12.3-14 21.3-11.2l165.5 51.5c9 2.8 14 12.3 11.2 21.3-2.3 7.3-9 11.9-16.3 11.9z"
            fill="#515151"
            p-id="8031"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289791012"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="12163"
          width="20"
          height="20"
        >
          <path
            d="M909 959H115a50 50 0 0 1-50-50V115a50 50 0 0 1 50-50h794a50 50 0 0 1 50 50v794a50 50 0 0 1-50 50z m-10-819a15 15 0 0 0-15-15H140a15 15 0 0 0-15 15v744a15 15 0 0 0 15 15h744a15 15 0 0 0 15-15V140z m-89.938 611H335a30 30 0 0 1 0-60h474.062a30 30 0 1 1 0 60z m0-209H335a30 30 0 0 1 0-60h474.062a30 30 0 1 1 0 60z m0-208H335a30 30 0 0 1 0-60h474.062a30 30 0 1 1 0 60zM215 751a30 30 0 1 1 30-30 30 30 0 0 1-30 30z m0-209a30 30 0 1 1 30-30 30 30 0 0 1-30 30z m0-208a30 30 0 1 1 30-30 30 30 0 0 1-30 30z"
            fill="#515151"
            p-id="12164"
          />
        </svg>
      ),
    },
    {
      svgIcons: (
        <svg
          d="1650289544068"
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="9381"
          width="20"
          height="20"
        >
          <path
            d="M781.041526 702.256158c-16.83134 0-34.491559-1.680269-52.873208-5.064342-85.793992-15.801894-151.311171-82.962503-213.415624-152.277172-62.652945 69.642127-129.096217 136.478348-217.570247 152.294568-70.713528 12.640901-130.713041 0.388856-173.508729-35.43607-40.167838-33.624818-61.400419-85.202521-61.400419-149.156088 0-64.520479 21.726837-116.567879 62.833047-150.514015 43.171242-35.655058 102.770642-47.420009 172.33909-34.026978 87.798649 16.901948 154.213267 86.10303 217.33284 156.718321 62.184271-69.613474 127.35148-138.577149 212.878388-156.647713l0 0c67.512626-14.261818 128.272455-3.003404 171.080423 31.705096 41.557488 33.694403 64.444754 87.947028 64.444754 152.765289 0 64.296375-21.394263 116.095112-61.869093 149.797701C869.62198 688.800705 828.659033 702.256158 781.041526 702.256158zM541.569456 514.873444c60.654428 67.89125 119.423927 129.252783 193.847963 142.959969 58.313104 10.744715 106.824981 1.688455 140.287093-26.172079 31.04711-25.853831 47.458895-67.01837 47.458895-119.045303 0-53.279461-17.161868-95.356788-49.629327-121.68134-33.737382-27.353998-81.32112-35.529191-137.603984-23.635303C661.437497 383.037836 602.76112 446.251553 541.569456 514.873444zM243.962338 362.741581c-37.352723 0-69.124334 10.190083-93.373621 30.216185-31.596625 26.093285-48.296983 67.471694-48.296983 119.659287 0 51.728128 16.276708 92.695169 47.070039 118.471229 33.419134 27.975145 82.102926 37.217646 140.779304 26.728758 77.165474-13.795191 136.761803-74.853825 197.828624-142.966109C426.068885 445.440072 366.364085 382.095371 289.87911 367.369995 273.809109 364.277564 258.462586 362.741581 243.962338 362.741581z"
            p-id="9382"
            fill="#515151"
          />
        </svg>
      ),
    },
  ];
  return (
    <>
      <div>
        <Menu
          direction="vertical"
          style={{ paddingTop: '0px', height: '100vh' }}
          onOpenedMenu={arr => {
            
            if(headData!==arr[0]){


                setheadData(arr[0])
                arr.length=0
              
              
              // eslint-disable-next-line prefer-destructuring
             
            }
          }}
        >
          {tabs.map((tab, index) =>
            currentTab === tab.key ? (
              <Menu.SubMenu
                hasBottomBar={bottomBar}
                style={{ background: 'white', paddingTop: '20px', height: 'auto' }}
                header={tab.name ? tab.name : ' '}
                id={tab.key}
                icon={svgIcons[index].svgIcons}
                key={tab.key}
                onClick={() => setCurrentTab(tab.key)}
              >
                {msg}
              </Menu.SubMenu>
            ) : (
              <Menu.SubMenu
                style={{ background: 'white', paddingTop: '20px', height: 'auto' }}
                header={tab.name ? tab.name : ' '}
                id={tab.key}
                icon={svgIcons[index].svgIcons}
                key={tab.key}
                onClick={() => setCurrentTab(tab.key)}
              >
                {msg}
              </Menu.SubMenu>
            ),
          )}
        </Menu>
      </div>
    </>
  );
};

export default LeftTabBar;
