/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from 'react';
import { Modal, Button, Loader, Notification } from 'react-windy-ui';
import { useTranslation } from 'react-i18next';
import PageContext from '../context/PageContext';
import { qrAttention, qrcode } from '../http/request';
import { aesDecrypt, aesEncrypt } from '../http/aesKey';

const PageController = () => {
  const pageContext = useContext(PageContext);
  const { panZoomRef, setPrintDialogOpen } = pageContext;
  const { t } = useTranslation('rightSidebar');

  const zoomIn = () => panZoomRef.current.zoomIn(2);
  const zoomOut = () => panZoomRef.current.zoomOut(2);
  const centerReset = () => {
    panZoomRef.current.autoCenter(1);
    panZoomRef.current.reset(1);
  };

  const [QRactive, setQRactive] = useState(false);
  const [QRsrc, setQRsrc] = useState('');
  const [LoadingActive, setLoadingActive] = useState(true);
  const key = '3jzeAdyH2gh8NiN8ByE1wS34WfZhV6aZ';

  const QRclose = () => setQRactive(false);
  let timer = null;
  const iTimer = () => {
    timer = setInterval(async () => {
      const data = await qrAttention(
        aesEncrypt(JSON.stringify({ scene_id: localStorage.getItem('scene_id') }), key),
      );
      const res = JSON.parse(aesDecrypt(data.data, key));
      if (res.res) {
        setQRactive(false);
        clearInterval(timer);
        setPrintDialogOpen(true);
        Notification.ok({
          body: '扫描成功',
          filled: true,
        });
      }
    }, 5000);
  };

  const getQR = async () => {
    const data = await qrcode(aesEncrypt(JSON.stringify({ timestamp: new Date().getTime() }), key));
    // console.log(JSON.parse(aesDecrypt(data.data, key)));
    setQRactive(true);
    setLoadingActive(true);
    setQRsrc(JSON.parse(aesDecrypt(data.data, key)).qr_code_url);
    localStorage.setItem('scene_id', JSON.parse(aesDecrypt(data.data, key)).scene_id);
    setLoadingActive(false);
  };
  const getAttention = async flag => {
    // if (localStorage.getItem('scene_id')) {
    //   const data = await qrAttention(
    //     aesEncrypt(JSON.stringify({ scene_id: localStorage.getItem('scene_id') }), key),
    //   );
    //   const res = JSON.parse(aesDecrypt(data.data, key));
    //   // console.log(JSON.parse(aesDecrypt(data.data, key)));
    //   if (res.res) {
    //     if (flag) {
    setPrintDialogOpen(true);
    //     } else {
    //       window.print();
    //     }
    //   } else {
    //     getQR();
    //     iTimer();
    //   }
    // } else {
    //   getQR();
    //   iTimer();
    // }
  };

  return (
    <div
      id="pageController"
      className="absolute z-20 opacity-75 hover:opacity-100 transition-all duration-150"
    >
      <div className="text-2xl px-8 border border-gray-200 rounded-full bg-white flex justify-center items-center leading-none select-none">
        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomIn}>
          <i className="material-icons">zoom_in</i>
        </div>

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={zoomOut}>
          <i className="material-icons">zoom_out</i>
        </div>

        <div className="p-3 hover:bg-gray-200 cursor-pointer flex" onClick={centerReset}>
          <i className="material-icons">center_focus_strong</i>
        </div>

        <div className="text-gray-400 p-3">|</div>

        <div
          className="p-3 hover:bg-gray-200 cursor-pointer flex"
          onClick={() => getAttention(false)}
        >
          <i className="material-icons">print</i>
        </div>

        <div
          className="p-3 hover:bg-gray-200 cursor-pointer flex"
          onClick={() => getAttention(true)}
        >
          <i className="material-icons">save</i>
        </div>

        <div className="text-gray-400 p-3">|</div>

        <a
          className="p-3 hover:bg-gray-200 cursor-pointer flex"
          href="https://space.bilibili.com/93824115"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="material-icons">help_outline</i>
        </a>
      </div>
      <Modal
        active={QRactive}
        type="primary"
        onCancel={QRclose}
        center={false}
        style={{ top: '3rem' }}
      >
        <Modal.Header>公众号</Modal.Header>
        <Modal.Body>
          <div style={{ height: 'auto' }}>
            <div className="flex flex-col text-center">
              <div className="text-sm">{t('about.sourceCode.body')}</div>
              <div className="flex flex-col mt-3">
                <div className="text-m">请先关注公众号，关注后方可下载</div>
                <Loader type="primary" active={LoadingActive} style={{ marginRight: '1rem' }} />
                <img src={QRsrc} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button hasMinWidth type="primary" onClick={QRclose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PageController;
