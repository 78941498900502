import CryptoJS from 'crypto-js';

/**
 * 加密
 * @param encryptString 要加密的字符串
 * @param key 秘钥
 * @returns {string} 加密后的字符串
 */
export const aesEncrypt = (encryptString, keys) => {
  const key = CryptoJS.enc.Utf8.parse(keys);
  const srcs = CryptoJS.enc.Utf8.parse(encryptString);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};
/**
 * 解密
 * @param decryptString 要解密的字符串
 * @param key 秘钥
 * @returns {string} 解密后的字符串
 */
export const aesDecrypt = (decryptString, keys) => {
  const key = CryptoJS.enc.Utf8.parse(keys);
  const decrypt = CryptoJS.AES.decrypt(decryptString, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};
