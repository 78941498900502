/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useContext, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PanZoom } from 'react-easy-panzoom';
import { Modal, Button } from 'react-windy-ui';
import AppContext from '../../context/AppContext';
import PageContext from '../../context/PageContext';
import alipay from '../RightSidebar/tabs/alipay.jpg';
import wechat from '../RightSidebar/tabs/wechat.jpg';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import RightSidebar from '../RightSidebar/RightSidebar';

import templates from '../../templates';
import PageController from '../../shared/PageController';
import PrintDialog from '../../shared/PrintDialog';
import PanZoomAnimation from '../../shared/PanZoomAnimation';

// eslint-disable-next-line import/order
import 'react-windy-ui/dist/wui.css';

const App = () => {
  const pageRef = useRef(null);
  const panZoomRef = useRef(null);
  const { i18n } = useTranslation();
  const [active, setActive] = useState(false);
  const close = () => setActive(false);
  const context = useContext(AppContext);
  const { state, dispatch } = context;
  const { theme, settings } = state;

  const pageContext = useContext(PageContext);
  const { setPageRef, setPanZoomRef } = pageContext;

  useEffect(() => {
    setPageRef(pageRef);
    setPanZoomRef(panZoomRef);
    i18n.changeLanguage(settings.language);
    const storedState = JSON.parse(localStorage.getItem('state'));
    dispatch({ type: 'import_data', payload: storedState });
  }, [dispatch, setPageRef, setPanZoomRef, i18n, settings.language]);

  return (
    <Suspense fallback="Loading...">
      <div className="h-screen grid grid-cols-5 items-center bg-blue-100">
        <LeftSidebar />
        <div className="relative z-10 h-screen overflow-hidden col-span-3 flex justify-center items-center">
          <PanZoom
            ref={panZoomRef}
            minZoom="0.4"
            autoCenter
            autoCenterZoomLevel={0.7}
            enableBoundingBox
            boundaryRatioVertical={0.8}
            boundaryRatioHorizontal={0.8}
            style={{ outline: 'none' }}
          >
            <div id="page" ref={pageRef} className="shadow-2xl break-words">
              {templates.find(x => theme.layout.toLowerCase() === x.key).component()}
            </div>
          </PanZoom>

          <PageController />
          <div
            className="absolute top-0 right-0 m-5 text-white text-sm font-medium py-2 px-5 rounded bg-red-600 hover:bg-red-700 cursor-pointer"
            onClick={() => setActive(true)}
          >
            赞助
          </div>
        </div>

        <div id="printPage" className="break-words">
          {templates.find(x => theme.layout.toLowerCase() === x.key).component()}
        </div>

        <RightSidebar />

        <PanZoomAnimation />
        <PrintDialog />
        <Modal
          active={active}
          type="primary"
          onCancel={close}
          center={false}
          style={{ top: '3rem', height: '800px' }}
        >
          <Modal.Header>打赏</Modal.Header>
          <Modal.Body>
            <div style={{ height: 'auto' }}>
              <div className="flex flex-col text-center items-center">
                <div className="text-sm">
                  如果Resumes对您有帮助，请麻烦打赏一下成为股东，让我买一杯咖啡、红牛，后面会给大家带来更多内容创作！
                </div>
                <div className="flex flex-col mt-3">
                  <div className="text-m mb-2">微信</div>
                  <img src={wechat} style={{ width: '20rem', height: '20rem' }} />
                </div>
                <div className="flex flex-col mt-3">
                  <div className="text-m">支付宝</div>
                  <img src={alipay} style={{ width: '20rem', height: '20rem' }} />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button hasMinWidth type="primary" onClick={close}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Suspense>
  );
};

export default App;
