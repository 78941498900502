/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Loader, Notification, PopConfirm } from 'react-windy-ui';

import PageContext from '../../../context/PageContext';
import { importJson } from '../../../utils';
import { qrAttention, qrcode } from '../../../http/request';
import { aesDecrypt, aesEncrypt } from '../../../http/aesKey';

const ActionsTab = ({ data, theme, dispatch }) => {
  const pageContext = useContext(PageContext);
  const { setPrintDialogOpen } = pageContext;
  const { t } = useTranslation('rightSidebar');
  const fileInputRef = useRef(null);
  const [QRactive, setQRactive] = useState(false);
  const [QRsrc, setQRsrc] = useState('');
  const [LoadingActive, setLoadingActive] = useState(true);
  const key = '3jzeAdyH2gh8NiN8ByE1wS34WfZhV6aZ';

  const QRclose = () => setQRactive(false);
  let timer = null;
  const iTimer = () => {
    timer = setInterval(async () => {
      const data = await qrAttention(
        aesEncrypt(JSON.stringify({ scene_id: localStorage.getItem('scene_id') }), key),
      );
      const res = JSON.parse(aesDecrypt(data.data, key));
      if (res.res) {
        setQRactive(false);
        clearInterval(timer);
        setPrintDialogOpen(true);
        Notification.ok({
          body: '扫描成功',
          filled: true,
        });
      }
    }, 5000);
  };
  const getQR = async () => {
    const data = await qrcode(aesEncrypt(JSON.stringify({ timestamp: new Date().getTime() }), key));
    // console.log(JSON.parse(aesDecrypt(data.data, key)));
    setQRactive(true);
    setLoadingActive(true);
    setQRsrc(JSON.parse(aesDecrypt(data.data, key)).qr_code_url);
    localStorage.setItem('scene_id', JSON.parse(aesDecrypt(data.data, key)).scene_id);
    setLoadingActive(false);
  };
  const getAttention = async () => {
    // if (localStorage.getItem('scene_id')) {
    //   const data = await qrAttention(
    //     aesEncrypt(JSON.stringify({ scene_id: localStorage.getItem('scene_id') }), key),
    //   );
    //   const res = JSON.parse(aesDecrypt(data.data, key));
    //   // console.log(JSON.parse(aesDecrypt(data.data, key)));
    //   if (res.res) {
    setPrintDialogOpen(true);
    //   } else {
    //     getQR();
    //     iTimer();
    //   }
    // } else {
    //   getQR();
    //   iTimer();
    // }
  };

  const exportToJson = () => {
    const backupObj = { data, theme };
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(backupObj))}`;
    const dlAnchor = document.getElementById('downloadAnchor');
    dlAnchor.setAttribute('href', dataStr);
    dlAnchor.setAttribute('download', `RxResumeBackup_${Date.now()}.json`);
    dlAnchor.click();
  };

  const loadDemoData = () => {
    dispatch({ type: 'load_demo_data' });
    dispatch({ type: 'save_data' });
  };
  const resetEverything = () => {
    dispatch({ type: 'reset' });
    dispatch({ type: 'save_data' });
  };
  const onOk = () => {
    resetEverything();
  };

  return (
    <div>
      <div className="shadow text-center text-sm p-5">{t('actions.disclaimer')}</div>

      <hr className="my-6" />

      <div className="shadow text-center p-5">
        <h6 className="font-bold text-sm mb-2">{t('actions.importExport.heading')}</h6>

        <p className="text-sm">{t('actions.importExport.body')}</p>

        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={e => importJson(e, dispatch)}
        />
        <a id="downloadAnchor" className="hidden" />

        <div className="mt-4 grid grid-cols-2 col-gap-6">
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="bg-gray-600 hover:bg-gray-700 text-white text-sm font-medium py-2 px-5 rounded"
          >
            <div className="flex justify-center items-center">
              <i className="material-icons mr-2 font-bold text-base">publish</i>
              <span className="text-sm">{t('actions.importExport.buttons.import')}</span>
            </div>
          </button>

          <button
            type="button"
            onClick={exportToJson}
            className="bg-gray-600 hover:bg-gray-700 text-white text-sm font-medium py-2 px-5 rounded"
          >
            <div className="flex justify-center items-center">
              <i className="material-icons mr-2 font-bold text-base">get_app</i>
              <span className="text-sm">{t('actions.importExport.buttons.export')}</span>
            </div>
          </button>
        </div>
      </div>

      <hr className="my-6" />

      <div className="shadow text-center p-5">
        <h6 className="font-bold text-sm mb-2">{t('actions.downloadResume.heading')}</h6>
        <div className="text-sm">{t('actions.downloadResume.body')}</div>

        <button
          type="button"
          onClick={() => getAttention()}
          className="mt-4 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-5 rounded"
        >
          <div className="flex justify-center items-center">
            <i className="material-icons mr-2 font-bold text-base">save</i>
            <span className="text-sm">{t('actions.downloadResume.buttons.saveAsPdf')}</span>
          </div>
        </button>
      </div>

      <hr className="my-6" />

      <div className="shadow text-center p-5">
        <h6 className="font-bold text-sm mb-2">{t('actions.loadDemoData.heading')}</h6>

        <div className="text-sm">{t('actions.loadDemoData.body')}</div>

        <button
          type="button"
          onClick={loadDemoData}
          className="mt-4 bg-green-600 hover:bg-green-700 text-white text-sm font-medium py-2 px-5 rounded"
        >
          <div className="flex justify-center items-center">
            <i className="material-icons mr-2 font-bold text-base">flight_takeoff</i>
            <span className="text-sm">{t('actions.loadDemoData.buttons.loadData')}</span>
          </div>
        </button>
      </div>

      <hr className="my-6" />

      <div className="shadow text-center p-5">
        <h6 className="font-bold text-sm mb-2">{t('actions.reset.heading')}</h6>

        <div className="text-sm">{t('actions.reset.body')}</div>
        <PopConfirm body="确定要重置吗?" onOk={onOk} position="topRight">
          <button
            type="button"
            className="mt-4 bg-red-600 hover:bg-red-700 text-white text-sm font-medium py-2 px-5 rounded"
          >
            <div className="flex justify-center items-center">
              <i className="material-icons mr-2 font-bold text-base">refresh</i>
              <span className="text-sm">{t('actions.reset.buttons.reset')}</span>
            </div>
          </button>
        </PopConfirm>
      </div>
      <Modal
        active={QRactive}
        type="primary"
        onCancel={QRclose}
        center={false}
        style={{ top: '3rem' }}
      >
        <Modal.Header>公众号</Modal.Header>
        <Modal.Body>
          <div style={{ height: 'auto' }}>
            <div className="flex flex-col text-center">
              <div className="text-sm">{t('about.sourceCode.body')}</div>
              <div className="flex flex-col mt-3">
                <div className="text-m">请先关注公众号，关注后方可下载</div>
                <Loader type="primary" active={LoadingActive} style={{ marginRight: '1rem' }} />
                <img src={QRsrc} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button hasMinWidth type="primary" onClick={QRclose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionsTab;
