import React, { useContext } from 'react';
import AppContext from '../context/AppContext';

const ImgField = ({ label }) => {
    const context = useContext(AppContext);
    const { state, dispatch } = context;
    const { data } = state;

    function pictureUpload(event) {
        var name = event.target.value;
        var fileName = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
        if (fileName !== "png" && fileName !== "jpg" && fileName !== "jpeg" && fileName !== "bmp") {
            alert("请选择格式为.png、.jpg、.jpeg、.bmp的图片！");
            return false;
        }
        var file = event.target.files[0];
        if (window.FileReader) {
            var fr = new FileReader();
            // 转化为bese64格式
            fr.readAsDataURL(file);
            // 成功后的回调
            fr.onloadend = function (e) {
                data.profile.photo = e.target.result;
                dispatch({ type: 'save_data' });
            };
        }
    }
    return <div>
        {label && (
            <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2">
                {label}
            </label>
        )}
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
                <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                >
                    <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <div style={{'justifyContent': 'center'}} className="flex text-sm text-gray-600">
                    <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                        <span>上传图片</span>
                        <input onChange={pictureUpload} id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    {/* <p className="pl-1">或者拖放</p> */}
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, JPEG, BMP</p>
            </div>
        </div>
    </div>
}

export default ImgField;
