/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Loader } from 'react-windy-ui';
import alipay from './alipay.jpg';
import wechat from './wechat.jpg';
import { qrcode } from '../../../http/request';
import { aesDecrypt, aesEncrypt } from '../../../http/aesKey';

const AboutTab = () => {
  const { t } = useTranslation('rightSidebar');
  const [active, setActive] = useState(false);
  const [QRactive, setQRactive] = useState(false);
  const [QRsrc, setQRsrc] = useState('');
  const [LoadingActive, setLoadingActive] = useState(true);

  const close = () => setActive(false);
  const QRclose = () => setQRactive(false);
  const key = '3jzeAdyH2gh8NiN8ByE1wS34WfZhV6aZ';
  const getQRsrc = async () => {
    setLoadingActive(true);
    setQRactive(true);
    const data = await qrcode(aesEncrypt(JSON.stringify({ timestamp: new Date().getTime() }), key));
    setQRsrc(JSON.parse(aesDecrypt(data.data, key)).qr_code_url);
    // console.log(JSON.parse(aesDecrypt(data.data, key)));
    
    localStorage.setItem("scene_id",JSON.parse(aesDecrypt(data.data, key)).scene_id)
    // console.log(localStorage);
    
    setLoadingActive(false);
  };

  return (
    <div>
      
      <div className="shadow text-center p-5 mt-2">
        <h6 className="font-bold text-sm mb-2">{t('about.documentation.heading')}</h6>

        <div className="text-sm">{t('about.documentation.body')}</div>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://space.bilibili.com/93824115"
          className="flex justify-center items-center mt-4 bg-gray-600 hover:bg-gray-700 text-white text-sm font-medium py-2 px-5 rounded"
        >
          <div className="flex justify-center items-center">
            <i className="material-icons mr-2 font-bold text-base">description</i>
            <span className="text-sm">{t('about.documentation.buttons.documentation')}</span>
          </div>
        </a>
      </div>

      <hr className="my-5" />

      <div className="shadow text-center p-5">
        <h6 className="font-bold text-sm mb-2">{t('about.sponsor.heading')}</h6>

        <div className="text-sm">{t('about.sponsor.body')}</div>

        <div className="grid grid-cols-1">
          <button
            className="mt-4 bg-red-600 hover:bg-red-700 text-white text-sm font-medium py-2 px-5 rounded"
            onClick={() => setActive(true)}
          >
            <div className="flex justify-center items-center">
              <i className="material-icons mr-2 font-bold text-base">gavel</i>
              <span className="text-sm">{t('about.sponsor.buttons.sponsor')}</span>
            </div>
          </button>
        </div>
      </div>

      <hr className="my-5" />

      <div className="shadow text-center p-5">
        <h6 className="font-bold text-sm mb-2">{t('about.sourceCode.heading')}</h6>

        <div className="text-sm">{t('about.sourceCode.body')}</div>
        <div className="grid grid-cols-1">
          <button
            className="mt-4 bg-green-600 hover:bg-green-700 text-white text-sm font-medium py-2 px-5 rounded"
            onClick={() => getQRsrc()}
          >
            <div className="flex justify-center items-center">
              <i className="material-icons mr-2 font-bold text-base">code</i>
              <span className="text-sm">{t('about.sourceCode.buttons.githubRepo')}</span>
            </div>
          </button>
        </div>
      </div>


      
      <Modal active={active} type="primary" onCancel={close} center={false} style={{ top: '3rem' }}>
        <Modal.Header>打赏</Modal.Header>
        <Modal.Body>
          <div style={{ height: 'auto' }}>
            <div className="flex flex-col text-center">
              <div className="text-sm">{t('about.sponsor.body')}</div>
              <div className="flex flex-col mt-3">
                <div className="text-m mb-2">微信</div>
                <img src={wechat} />
              </div>
              <div className="flex flex-col mt-3">
                <div className="text-m">支付宝</div>
                <img src={alipay} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button hasMinWidth type="primary" onClick={close}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        active={QRactive}
        type="primary"
        onCancel={QRclose}
        center={false}
        style={{ top: '3rem' }}
      >
        <Modal.Header>公众号</Modal.Header>
        <Modal.Body>
          <div style={{ height: 'auto' }}>
            <div className="flex flex-col text-center">
              <div className="text-sm">{t('about.sourceCode.body')}</div>
              <div className="flex flex-col mt-3">
                <div className="text-m">公众号二维码</div>
                <Loader type="primary" active={LoadingActive} style={{ marginRight: '1rem' }} />
                <img src={QRsrc} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button hasMinWidth type="primary" onClick={QRclose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AboutTab;
