import React from 'react';
import { useTranslation } from 'react-i18next';

import TextArea from '../../../shared/TextArea';
import TextField from '../../../shared/TextField';
import Checkbox from '../../../shared/Checkbox';

const ObjectiveTab = ({ data, onChange }) => {
  const { t } = useTranslation('leftSidebar');

  return (
    <div>
      <div className="mb-6 grid grid-cols-6 items-center">
        <div className="col-span-1">
          <Checkbox
            checked={data.objective.enable}
            onChange={v => onChange('data.objective.enable', v)}
          />
        </div>
        <div className="col-span-5">
          <TextField
            placeholder="Heading"
            value={data.objective.heading}
            onChange={v => onChange('data.objective.heading', v)}
          />
        </div>
      </div>

      <hr className="my-6" />

      <TextArea
        rows="15"
        className="mb-4"
        label={t('objective.objective.label')}
        value={data.objective.body}
        placeholder="对待工作认真负责，善于沟通、协调，有较强的组织能力与团队精神。
        活泼开朗、乐观上进、有爱心并善于施教并行。
        上进心强、勤于学习能不断提高自身的能力与综合素质。"
        onChange={v => onChange('data.objective.body', v)}
      />
    </div>
  );
};

export default ObjectiveTab;
