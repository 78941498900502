import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../../shared/TextField';
import ImgField from '../../../shared/ImgField';

const ProfileTab = ({ data, onChange }) => {
  const { t } = useTranslation('leftSidebar');

  return (
    <div>
      <TextField
        className="mb-6"
        placeholder="个人信息"
        value={data.profile.heading}
        onChange={v => onChange('data.profile.heading', v)}
      />

      <hr className="my-6" />

      <ImgField
        className="mb-6"
        label={t('profile.photoUrl.label')}
      />

      {/* <TextField
        className="mb-6"
        label={t('profile.photoUrl.label')}
        placeholder="https://i.imgur.com/..."
        value={data.profile.photo}
        onChange={v => onChange('data.profile.photo', v)}
      /> */}

      <TextField
        className="mb-6"
        label={t('profile.Name.label')}
        placeholder="例：张三"
        value={data.profile.Name}
        onChange={v => onChange('data.profile.Name', v)}
      />


      <TextField
        className="mb-6"
        label={t('profile.subtitle.label')}
        placeholder="例：Java开发"
        value={data.profile.subtitle}
        onChange={v => onChange('data.profile.subtitle', v)}
      />

      <hr className="my-6" />

      <TextField
        className="mb-6"
        label={t('profile.address.label')}
        placeholder="例：广州市天河区"
        value={data.profile.address}
        onChange={v => onChange('data.profile.address', v)}
      />

      <hr className="my-6" />

      <TextField
        className="mb-6"
        label={t('profile.phone.label')}
        placeholder="例：13000000000"
        value={data.profile.phone}
        onChange={v => onChange('data.profile.phone', v)}
      />

      <TextField
        className="mb-6"
        label={t('profile.school.label')}
        placeholder="例：B站大学"
        value={data.profile.school}
        onChange={v => onChange('data.profile.school', v)}
      />

      <TextField
        className="mb-6"
        label={t('profile.email.label')}
        placeholder="例：xxx@qq.com"
        value={data.profile.email}
        onChange={v => onChange('data.profile.email', v)}
      />
    </div>
  );
};

export default ProfileTab;
